import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';
import mainLogo from './Header/White.png';

const Footer = lazy(() => import('./Footer/footer'));


export const LoadingScreen = () => {
  return (
    <div className='unallow-overflow'>
      <div className='loading-screen'>
        <div className='loading-screen__content'>
          <img
            className='loading-logo'
            src={mainLogo}
            alt='Logo'
          />
        </div>
      </div>
    </div>
    )
}


export default function Layout() {
  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen/>}>
        <Outlet />
        <Footer />
      </Suspense>
    </ScrollToTop>
  );
}
