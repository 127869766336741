/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import BlackHeaderBar from '../../Layout/Header/blackHeader';
import HeaderBar from '../../Layout/Header/header';

const HeroPractice = ({
  backgroundImage,
  primaryHeading,
  secondaryHeading,
  subHeading,
  color,
  subHeadingWidth,
}) => {
  if (primaryHeading === 'Oil & Gas' || primaryHeading === 'Aviation' || primaryHeading === 'Technology' || primaryHeading === 'Arbitration' || primaryHeading === 'Construction' || primaryHeading === 'Transportation' || primaryHeading === 'Energy') {
    color = 'white'
  }
  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100vh', // Adjust the height as needed
      }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with 50% opacity
        }}
      />
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          width: '100%',
          height: '100%', // Ensure the image covers the entire container
          objectFit: 'cover',
        }}
      />

      {/* Black overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <HeaderBar />
        </div>
      </div>

      <div className='mobile-headers-box seven-percent-left adjust-practices'>

          <p
            className='main-headers seven-percent-left'
            style={{
              color: 'white',
              fontSize: '50px',
              maxWidth: "600px",
              fontWeight: '400',
            }}>
            {secondaryHeading}
          </p>

          <p
            className='main-headers seven-percent-left'
            style={{
              color: 'white',
              fontSize: '50px',
              maxWidth: "600px",
              fontWeight: '400',
            }}>
            {primaryHeading}
          </p>

          <p
            className='header-sub-text'
            style={{
              color: 'white',
              fontSize: '18px',
              maxWidth: '390px',
              fontStyle: 'italic',
              fontWeight: '400',
              fontFamily: 'Montserrat',
            }}>
            {subHeading}
          </p>
        </div>
      <p
        className='under-1024-disapear'
        style={{
          position: 'absolute',
          top: 200,
          left: 80,
          color: color,
          fontSize: '30px',
          width: '800px',
          fontWeight: '400',
        }}>
        {secondaryHeading}
      </p>

      <p
        className='under-1024-disapear'
        style={{
          position: 'absolute',
          top: 300,
          left: 85,
          right: 0,
          color: color,
          fontSize: '50px',
          width: '800px',
          fontWeight: '400',
        }}>
        {primaryHeading}
      </p>

      <p
        className='under-1024-disapear'
        style={{
          position: 'absolute',
          top: 400,
          left: 85,
          right: 0,
          color: color,
          fontSize: '18px',
          width: subHeadingWidth,
          fontStyle: 'italic',
          fontWeight: '400',
          fontFamily: 'Montserrat',
          display: "inline-block"
        }}>
        {subHeading}
      </p>
    </div>
  );
};

HeroPractice.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  primaryHeading: PropTypes.string.isRequired,
  secondaryHeading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  subHeadingWidth: PropTypes.string.isRequired,
};

export default HeroPractice;
