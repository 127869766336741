import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { register } from 'swiper/element/bundle';

import Layout, { LoadingScreen } from './components/Layout/Layout.jsx';
import NotFound from './pages/NotFound/NotFound.jsx';
import routes from './routes/index.js';
import ScrollToTopButton from './components/ScrollUpButtom.jsx';

function App() {
  register();

  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <NotFound />,
      children: routes,
    },{
      path: '/loading-page',
      element: <LoadingScreen />,
    }
  ]);

  return (
  <>
    <RouterProvider router={router} />
    <ScrollToTopButton />
  </>
  );
}

export default App;
