const LegalPerspectiveHeaderText = () => {
  return (
    <>

        <div className='mobile-headers-box seven-percent-left adjust-insight'>
          <p
            className='main-headers seven-percent-left'
            style={{
              color: 'white',
              fontSize: '50px',
              maxWidth: "600px",
              fontWeight: '400',
            }}>
            New International Arbitration Centre Launches in{" "}
            <span
              style={{
                fontWeight: '100',
                fontStyle: 'italic',
              }}>
              Abu Dhabi (ArbitrateAD)
            </span>
          </p>
        </div>
      <h1
        className='under-1024-disapear'
        style={{
          position: 'absolute',
          color: 'white',
          top: 571,
          left: 85,
          right: 0,
          fontFamily: 'Montserrat',
          fontSize: '60px',
          fontWeight: 400,
          lineHeight: '73px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}>
        New International Arbitration Centre Launches in{" "}
        <span
          style={{
            fontFamily: 'Montserrat',
            fontSize: '60px',
            fontWeight: 100,
            fontStyle: 'italic',
            lineHeight: '73px',
            letterSpacing: '0em',
          }}>Abu Dhabi (ArbitrateAD)
        </span>
      </h1>
    </>
  );
};

export default LegalPerspectiveHeaderText;
